/**
 *  Created by patrick.adair on 04.09.18.
 */

module gmtApp.exchange {
    const moduleName = 'gmtApp.exchange';
    angular.module(moduleName, ['ngCordova', 'gmtApp.images', 'gmtApp.workflow', 'gmtApp.metrics', 'gmtApp.utilities', 'gmtApp.crypto', 'gmtApp.restClient']);

    import IImageDataMap = gmtApp.images.IImageDataMap;
    type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

    export interface IWorkflowWithImages extends IWorkflow {
        images: IImageDataMap;
    }

    export interface IWorkflowsExchangeObject {
        version: number,
        workflows: IWorkflowWithImages[],
        images : IImageDataMap
    }

    export interface IImageDataBlobMap {
        [key:string]: Blob
    }

    export interface IWorkflowsExchangeObjectWithBlobs extends Omit<IWorkflowsExchangeObject, 'images'> {
        images : IImageDataBlobMap
    }
}
